<template>
  <div class="statistics">
    <el-row :gutter='23'>
      <el-col :span="7">
        <div class="dvs-drawer">
          <el-card class="dvs-card-head">
            <div class="dvs-details">
              <div class="dvs-title">
              </div>
              <div class="dvs-main">
                <el-row>
                  <div class="dvs-img">
                    <img src='src/logo1.gif' width='40%' title='杉谷科技'/>
                  </div>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <div class="dvs-status">
                      <div class="change-icon">
                        <i class="el-icon-success"></i>
                      </div>
                      {{ ipuNum }} 台 <br> 实时分析点位
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="dvs-status">
                      <div class="change-icon">
                        <i class="el-icon-error"></i>
                      </div>
                      0 台<br> 离线分析点位
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="dvs-details">
              <div class="dvs-title"></div>
              <div class="dvs-main">
                <el-row>
                  <el-card shadow="always" v-for="item in dvs_infos" :key="item.pk"
                           @click.native="jumpNvaMenu('EventCenter')">
                    <i class="el-icon-message-solid"></i> <span>{{ item.title }}</span>
                    <div style="float: right" v-if="item.title==='今日报警 （未处理 / 全部）' || item.title==='本月报警 （未处理 / 全部）' ">
                      <span style="color: red">{{ item.num1 }}</span> / <span>{{
                        item.num
                      }}</span></div>
                    <div style="float: right" v-else><span>{{ item.num }}</span> / <span>{{
                        item.num1
                      }}</span></div>
                  </el-card>
                </el-row>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>

      <el-col :span="8">
        <el-card class="dvs-card-head">
          <el-card class="dvs-card" shadow="never">
            <div slot="header" class="media-title">
              <span>集群状态</span>
            </div>
            <div class="dvs-main">
              <el-row>
                <div class="dvs-img-s">
                  <img src='src/icon3.gif' width='50%' title='杉谷科技'/>
                </div>
              </el-row>
              <el-row :gutter="23">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
                         @select="handleSelect">
                  <el-menu-item index="1"><i class="el-icon-link"></i>氯碱分厂IPU集群</el-menu-item>
                  <el-menu-item index="2"><i class="el-icon-link"></i>有机硅分厂IPU集群</el-menu-item>
                </el-menu>
                <div class="dvs-details" v-show="showDetails.menuIndex1">
                  <el-col :span="8">
                    <el-card shadow="never">
                      <div class="dvs-status">
                        <el-progress type="circle" :percentage="34" :width="100"></el-progress>
                      </div>
                      <div class="dvs-status-s">
                        CPU状态
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="8">
                    <el-card shadow="never">
                      <div class="dvs-status">
                        <el-progress type="circle" :percentage="21" :width="100"></el-progress>
                      </div>
                      <div class="dvs-status-s">
                        内存占用
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="8">
                    <el-card shadow="never">
                      <div class="dvs-status">
                        <el-progress type="circle" :percentage="12" :width="100"></el-progress>
                      </div>
                      <div class="dvs-status-s">
                        硬盘容量
                      </div>
                    </el-card>
                  </el-col>
                </div>
                <div class="dvs-details" v-show="showDetails.menuIndex2">
                  <el-col :span="8">
                    <el-card shadow="never">
                      <div class="dvs-status">
                        <el-progress type="circle" :percentage="21" :width="100"></el-progress>
                      </div>
                      <div class="dvs-status-s">
                        CPU状态
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="8">
                    <el-card shadow="never">
                      <div class="dvs-status">
                        <el-progress type="circle" :percentage="13" :width="100"></el-progress>
                      </div>
                      <div class="dvs-status-s">
                        内存占用
                      </div>
                    </el-card>
                  </el-col>
                  <el-col :span="8">
                    <el-card shadow="never">
                      <div class="dvs-status">
                        <el-progress type="circle" :percentage="15" :width="100"></el-progress>
                      </div>
                      <div class="dvs-status-s">
                        硬盘容量
                      </div>
                    </el-card>
                  </el-col>
                </div>
              </el-row>
            </div>
          </el-card>
        </el-card>
      </el-col>
      <el-col :span="9">
        <el-card class="dvs-card-head">
          <el-card class="dvs-card" shadow="never">
            <div slot="header" class="media-title">
              <span>报警点位预览</span>
            </div>
            <el-row :gutter="8">
              <el-col :span="6">
                <div style="padding-bottom: 20px;display: inline-block;">
                  <el-select v-model="selectFcatoryValue" placeholder="兴瑞江边排口" @change="onSelectChangeFactory">
                    <el-option
                        v-for="item in selectDataFcatory"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="10">
                <div style="padding-bottom: 20px;display: inline-block;">
                  <el-select v-model="selectValue" placeholder="兴瑞江边排口" @change="onSelectChange">
                    <el-option
                        v-for="item in selectData"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="8">
                <div v-show="ykShow" style="display: inline-block;margin-left: 20px;margin-top: 10px">
                  <el-link type="primary" @click="jumpNvaMenu('YKcenter')">盐库报警事件详情</el-link>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-image :src="imgSrc" :preview-src-list="[imgSrc,]" style="height: 320px"></el-image>
            </el-row>
          </el-card>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter='23'>
      <el-col :span="15">
        <div class="dvs-charts">
          <el-card>
            <el-row>
              <div style="float: right ;margin-bottom: 20px">
                <el-radio-group v-model="labelAlarm" size="small">
                  <el-radio-button label="left" @click.native="initECharts('labelAlarmDay')">日</el-radio-button>
                  <el-radio-button label="right" @click.native="initECharts('labelAlarmMonth')">月</el-radio-button>
                  <el-radio-button label="top" @click.native="initECharts('labelAlarmYear')">年</el-radio-button>
                </el-radio-group>
              </div>
            </el-row>
            <Echarts :chartsType="chartsLine"></Echarts>
          </el-card>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="dvs-charts">
          <el-card>
            <el-row>
              <div style="float: right ;margin-bottom: 20px">
                <el-radio-group v-model="labelAlarm_site" size="small">
                  <el-radio-button label="left" @click.native="initECharts('labelAlarm_siteDay')">日</el-radio-button>
                  <el-radio-button label="right" @click.native="initECharts('labelAlarm_siteMonth')">月</el-radio-button>
                  <el-radio-button label="top" @click.native="initECharts('labelAlarm_siteYear')">年</el-radio-button>
                </el-radio-group>
              </div>
            </el-row>
            <Echarts :chartsType="chartsTimeLine"></Echarts>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Echarts from "./Echarts.vue";
import {queryProjects, queryAlarmAll, statisticsAlarm} from '../../api/requests.js'
import {EventBus} from "../store/bus";

export default {
  name: "Statistics",
  components: {
    Echarts,
  },
  data() {
    return {
      chartsLine: 'line',
      chartsPie: 'pie',
      chartsBar: 'bar',
      labelAlarm: 'right',
      labelAlarm_site: 'right',
      ykShow: false,
      chartsTimeLine: 'timeLine',
      imgSrc: 'https://www.ipu-cloud.com/pics/xingfa/imgs/118.png',
      activeIndex: "1",
      selectData: [],
      selectDataFcatory: [],
      selectValue: '',
      selectFcatoryValue: '',
      ipuNum: 0,
      showDetails: {
        'menuIndex1': true,
        'menuIndex2': false,
      },
      dvs_infos: [
        {icon: "", title: '今日报警 （未处理 / 全部）', num: 0, num1: 0},
        {icon: "", title: '本月报警 （未处理 / 全部）', num: 0, num1: 0},
        {icon: "", title: '报警点位 （今日 / 本月）', num: 0, num1: 0},
        {icon: "", title: '历史报警 （今年 / 总数)', num: 0, num1: 0},
      ],
    }
  },
  methods: {
    jumpNvaMenu(item) {
      this.$router.push({
        name: item
      })
    },
    handleSelect(key, keyPath) {
      let selectMenuIndex = 'menuIndex' + keyPath[0]
      this.showDetails[selectMenuIndex] = true
      let showDetails = this.showDetails
      Object.keys(this.showDetails).forEach(function (item) {
        if (item !== selectMenuIndex) {
          showDetails[item] = false
        }
      })
      this.showDetails = showDetails
    },
    onSelectChange(value) {
      this.imgSrc = this.nodesDataFactory[value].camera_img
      if (this.nodesDataFactory[value].address === "氯碱一车间盐库化盐池") {
        this.ykShow = true
      } else {
        this.ykShow = false
      }
      if (this.labelAlarm_site === 'right') {
        this.initECharts('labelAlarm_siteMonth', this.nodesDataFactory[value].address)
      }
      if (this.labelAlarm_site === 'left') {
        this.initECharts('labelAlarm_siteDay', this.nodesDataFactory[value].address)
      }
      if (this.labelAlarm_site === 'top') {
        this.initECharts('labelAlarm_siteYear', this.nodesDataFactory[value].address)

      }
    },
    onSelectChangeFactory(value) {
      let selectData = []
      let index = 0
      let nodesDataFactory = []
      this.nodesData.forEach((item) => {
        if (item.control_room === this.selectDataFcatory[value].label) {
          let option = {}
          option['value'] = index
          option['label'] = item.address
          selectData.push(option)
          nodesDataFactory.push(item)
          index++
        }
      });
      this.selectData = selectData
      this.selectValue = 0
      this.imgSrc = nodesDataFactory[0].camera_img
      this.nodesDataFactory = nodesDataFactory
      this.onSelectChange(0)
    },
    sendInformations(type, item, data) {
      // 改变选中
      EventBus.$emit('type', type)
      EventBus.$emit('siteInfos', item)
      EventBus.$emit('alarmData', data)
    },
    initECharts(type, siteInfo) {
      if (!siteInfo) {
        if (this.selectValue === "") {
          siteInfo = '兴瑞江边排口'
        } else {
          siteInfo = this.nodesDataFactory[this.selectValue].address
        }
      }
      if (type === "labelAlarmDay") {
        let that = this
        let param = {type: "labelAlarmDay"}
        statisticsAlarm(param).then(res => {
          console.log(res)
          that.sendInformations("labelAlarmDay", 'statistics', res)
        })
      }
      if (type === "labelAlarmMonth") {
        let that = this
        let param = {type: "labelAlarmMonth"}
        statisticsAlarm(param).then(res => {
          that.sendInformations("labelAlarmMonth", 'statistics', res)
        })
      }
      if (type === "labelAlarmYear") {
        let that = this
        let param = {type: "labelAlarmYear"}
        statisticsAlarm(param).then(res => {
          that.sendInformations("labelAlarmYear", 'statistics', res)
        })
      }
      if (type === "labelAlarm_siteDay") {
        let that = this
        let param = {type: "labelAlarm_siteDay", siteInfo: siteInfo}
        statisticsAlarm(param).then(res => {
          that.sendInformations("labelAlarm_siteDay", siteInfo, res)
        })
      }
      if (type === "labelAlarm_siteMonth") {
        let that = this
        let param = {type: "labelAlarm_siteMonth", siteInfo: siteInfo}
        statisticsAlarm(param).then(res => {
          that.sendInformations("labelAlarm_siteMonth", siteInfo, res)
        })
      }
      if (type === "labelAlarm_siteYear") {
        let that = this
        let param = {type: "labelAlarm_siteYear", siteInfo: siteInfo}
        statisticsAlarm(param).then(res => {
          that.sendInformations("labelAlarm_siteYear", siteInfo, res)
        })
      }
    },
  },
  mounted() {
    queryProjects().then(res => {
      let selectDataFcatory = []
      let index2 = 0
      let listFcatory = []
      res.data.data.forEach((item) => {
        if (!listFcatory.includes(item.control_room)) {
          listFcatory.push(item.control_room)
        }
      });
      listFcatory.forEach((item) => {
        let option = {}
        option['value'] = index2
        option['label'] = item
        selectDataFcatory.push(option)
        index2++
      });
      this.selectDataFcatory = selectDataFcatory
      this.selectFcatoryValue = 0
      let selectData = []
      let index = 0
      let nodesDataFactory = []
      res.data.data.forEach((item) => {
        if (item.control_room === listFcatory[0]) {
          let option = {}
          option['value'] = index
          option['label'] = item.address
          selectData.push(option)
          nodesDataFactory.push(item)
          index++
        }
      });
      this.selectData = selectData
      this.selectValue = 0
      this.imgSrc = nodesDataFactory[0].camera_img
      this.nodesDataFactory = nodesDataFactory
      this.nodesData = res.data.data
      this.ipuNum = Array.from(new Set(res.data.data)).length
    });
    let user = localStorage.getItem('Authorization');
    queryAlarmAll({'info': user}).then(res => {
      this.dvs_infos[0]['num'] = res.data.alarm_day
      this.dvs_infos[0]['num1'] = res.data.alarm_day_noHandled
      this.dvs_infos[1]['num'] = res.data.alarm_month
      this.dvs_infos[1]['num1'] = res.data.alarm_month_noHandled
      this.dvs_infos[2]['num'] = res.data.alarm_day_address
      this.dvs_infos[2]['num1'] = res.data.alarm_month_addres
      this.dvs_infos[3]['num'] = res.data.alarm_len
      this.dvs_infos[3]['num1'] = res.data.alarm_len
    });

    this.initECharts('labelAlarm_siteMonth');
    this.initECharts('labelAlarmMonth');

  }
}
</script>

<style scoped>
.change-icon {
  font-size: 2vw;
}

.dvs-details .el-card {
  background-color: transparent;
  border: 0;
}

.dvs-main {
  margin-top: 2vh;
}

.dvs-img {
  margin-top: -3vh;
  text-align: center;
  width: 100%
}

.dvs-status {
  width: 100%;
  text-align: center;
}

.dvs-status-s {
  margin-top: 2vh;
  width: 100%;
  text-align: center;
}

.dvs-charts {
  margin-top: 2vh;
}

.dvs-img-s {
  margin-top: -5vh;
  width: 100%;
  text-align: center;
}

.dvs-card {
  background-color: transparent;
  border: 0;
}

.change-icon {
  font-size: 2vw;
}

.dvs-details {
  margin-top: 2vh;
}

.dvs-details .dvs-main {
  margin-top: 2vh;
}

.dvs-details .el-card {
  width: 99%;
  margin-top: 1vh;
}


.dvs-title {
  color: white;
  line-height: 4vh;
}

.dvs-card-head {
  height: 53vh;
}

.dvs-camera {
  margin-top: 5vh;
}
</style>
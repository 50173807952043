<template>
  <div>
    <div :id="chartsType" :style="{height: '300px'}"></div>
  </div>
</template>

<script>
import {EventBus} from "../store/bus";

export default {
  name: 'Echarts',
  props: ['chartsType',],
  data() {
    return {
      msg: 'Welcome to kalacloud.com',
      Bartype: '',
      Linetype: '',
      Pietype: '',
      TimeLinetype: '',
      type: '',
      alarm: '',
      alarm_month: '',
      alarm_day: '',
      dataBar: {
        title: {
          text: '',
          x: 'center',
          y: '7px',
          textStyle: {
            fontSize: 16
          },
          textAlign: 'left'
        },
        tooltip: {},
        xAxis: {
          data: ["水星", "金星", "地球", "火星", "木星", "土星", "金王星", "海王星"]
        },
        yAxis: {},
        series: [{
          name: '数量',
          type: 'bar',
          data: [0, 0, 1, 2, 79, 82, 27, 14]
        }]
      },
      dataLine: {
        title: {
          text: '报警统计',
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['叉车作业', '颜色异常', '泡沫异常', '接打/玩手机', '人员睡觉'],
          type: 'scroll',
          orient: 'vertical',
          right: 10,
          top: 75,
          // bottom: 20,
        },
        grid: {
          left: '3%',
          right: '23%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['2022-10-07', '2022-10-08', '2022-11-09', '2022-10-10', '2022-10-11', '2022-10-12', '2022-10-13', '2022-10-14', '2022-10-15', '2022-10-16', '2022-10-17', '2022-10-18', '2022-10-19', '2022-10-20', '2022-10-21', '2022-11-22', '2022-10-23', '2022-10-24', '2022-10-25', '2022-10-26', '2022-10-27', '2022-10-28', '2022-10-29', '2022-10-30', '2022-10-31', '2022-11-01', '2022-11-02', '2022-11-03', '2022-11-04']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '叉车作业',
            type: 'line',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1]
          },
          {
            name: '人员睡觉',
            type: 'line',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0]
          },
          {
            name: '颜色异常',
            type: 'line',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 1, 3, 1, 2, 1, 1, 3, 1, 1, 1, 1, 2, 0]
          },
          {
            name: '接打/玩手机',
            type: 'line',
            data: [2, 11, 9, 4, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 0]
          },
          {
            name: '泡沫异常',
            type: 'line',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
        ]
      },
      dataPie: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 100,
          top: 40,
          bottom: 20,
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 9, name: '已启用'},
              {value: 0, name: '已停用'},
              {value: 0, name: '启用中'},
              {value: 0, name: '停用中'},
              {value: 0, name: '待用中'}
            ]
          }
        ]
      },
      datatimeLine: {
        title: {
          text: '报警时刻图'
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'time',
        }],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: () => {
              return '';
            }
          },
        },
        legend: {
          data: ['', '']
        },
        series: [
          {
            name: '',
            type: 'scatter',
            showSymbol: true,
            data: []
          },
          {
            name: '',
            type: 'scatter',
            showSymbol: true,
            data: []
          },]
      },
      datatimeLine_set: {
        title: {
          text: '报警时刻图'
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'time',
        }],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: () => {
              return '';
            }
          },
        },
        legend: {
          data: ['', '']
        },
        series: [
          {
            name: '',
            type: 'scatter',
            showSymbol: true,
            data: []
          },
          {
            name: '',
            type: 'scatter',
            showSymbol: true,
            data: []
          },]
      },
      datatimeLine_defult: {
        title: {
          text: '报警时刻图'
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return '<div>' + params[0].marker + params[0].seriesName + '<br>' + '<span style="color: #409EFF;">' + params[0].value[0] + '</span></div>';
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'time',
        }],
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: () => {
              return '';
            }
          },
        },
        legend: {
          data: ['', '']
        },
        series: [
          {
            name: '',
            type: 'scatter',
            showSymbol: true,
            data: []
          },
          {
            name: '',
            type: 'scatter',
            showSymbol: true,
            data: []
          },]
      },
      siteInfos: '',
      alarmData: '',
    }
  },
  mounted() {
    if (this.chartsType === 'bar') {
      this.drawLine(this.dataBar, this.chartsType);
      this.Bartype = this.chartsType
    }
    if (this.chartsType === 'line') {
      this.drawLine(this.dataLine, this.chartsType);
      this.Linetype = this.chartsType
    }
    if (this.chartsType === 'pie') {
      document.getElementById(this.chartsType).style.height = "200px";
      this.Pietype = this.chartsType
      this.drawLine(this.dataPie, this.chartsType);
    }
    if (this.chartsType === 'timeLine') {
      this.TimeLinetype = this.chartsType
      let datatimeLine = this.datatimeLine
      this.drawTimeLine(datatimeLine, this.chartsType)
    }
    EventBus.$on("siteInfos", (msg) => {
      this.siteInfos = msg
    });
    EventBus.$on("alarmData", (msg) => {
      this.alarmData = msg
    });
    EventBus.$on("type", (msg) => {
      this.type = msg
    });
  },
  methods: {
    drawLine(data, type) {
      // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
      let myChart = this.$echarts.init(document.getElementById(type))
      // 绘制图表
      myChart.setOption(data);
    },
    drawTimeLine(data, type) {
      // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
      let myChart = this.$echarts.init(document.getElementById(type))
      myChart.setOption(data);
    }
  },
  watch: {
    alarmData() {
      if (this.siteInfos === 'statistics') {
        let xdata = Object.keys(this.alarmData.data.alarm)
        let type_data = []
        this.alarmData.data.alarm[xdata[0]].forEach(res => {
          type_data.push(res[0])
        })
        let yseries = []
        for (let index in type_data) {
          let data = []
          let def = {
            type: 'line',
          }
          xdata.forEach(res => {
            data.push(this.alarmData.data.alarm[res][index][1])
          })
          def['name'] = type_data[index]
          def['data'] = data
          yseries.push(def)
        }
        let dataLine = this.dataLine
        dataLine['legend']['data'] = type_data
        dataLine['xAxis']['data'] = xdata
        dataLine['series'] = yseries
        this.drawLine(dataLine, this.Linetype)
      } else {
        let type_data = []
        if (this.alarmData.data.alarm.length > 0) {
          let datatimeLine_defult = this.datatimeLine_defult
          this.drawTimeLine(datatimeLine_defult, this.TimeLinetype)
          this.alarmData.data.alarm.forEach(res => {
            type_data.push(res.fields.warnningType)
          })
          type_data = Array.from(new Set(type_data))
          let yseries = []
          for (let index in type_data) {
            let data = []
            let def = {
              type: 'scatter',
              showSymbol: true,
            }
            this.alarmData.data.alarm.forEach(res => {
              let data_time = []
              let date = new Date(res.fields.datetime);
              res.fields.datetime = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
              data_time.push(res.fields.datetime)
              data_time.push(Math.random().toFixed(2))
              data.push(data_time)
            })
            def['name'] = type_data[index]
            def['data'] = data
            yseries.push(def)
          }
          let datatimeLine = this.datatimeLine
          datatimeLine['legend']['data'] = type_data
          datatimeLine['series'] = yseries
          if (this.type == 'labelAlarm_siteDay') {
            let format = {
              formatter: {
                day: '{d}日',
                hour: '{HH}:{mm}',
                minute: '{HH}:{mm}',
              }
            }
            datatimeLine['xAxis'][0].axisLabel = format
          }
          if (this.type == 'labelAlarm_siteMonth') {
            let format = {
              // formatter: '{MM}月{dd}日'
              formatter: {
                day: '{MMM}{d}日',
              }
            }
            datatimeLine['xAxis'][0].axisLabel = format
          }
          if (this.type == 'labelAlarm_siteYear') {
            let format = {
              formatter: {
                month: '{MMM}',
                day: '{d}日'
              }
            }
            datatimeLine['xAxis'][0].axisLabel = format
          }
          this.drawTimeLine(datatimeLine, this.TimeLinetype)
        } else {
          let datatimeLine_defult = this.datatimeLine_defult
          this.drawTimeLine(datatimeLine_defult, this.TimeLinetype)
        }
      }
    },
  },
}
</script>